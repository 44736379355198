import React from 'react';
import createIcon from '../utils/createIcon';

const ClosePopupIcon = createIcon(
  <>
    <path
      d="M18.75 5.25L5.25 18.75"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.75 18.75L5.25 5.25"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>,
  'ClosePopup'
);

export default ClosePopupIcon;
