import React from 'react';
import cn from 'classnames';
import { Slot, type AsChildProps } from '../Slot';
import * as styles from './styles.module.css';

type SizeType = 'x2Large' | 'xLarge' | 'large' | 'medium' | 'small';
type VariantType =
  | 'brandPrimary'
  | 'brandSecondary'
  | 'secondary'
  | 'secondaryOverlay'
  | 'tertiary';

export type ButtonArgs = {
  size?: SizeType;
  variant?: VariantType;
  disabled?: boolean;
  className?: string;
};

export type ButtonProps = AsChildProps<
  React.PropsWithChildren<ButtonArgs>,
  React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonArgs
> &
  React.RefAttributes<HTMLButtonElement>;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { asChild, size = 'large', variant = 'brandPrimary', disabled, className = '', ...props },
    ref
  ) => {
    const classNameNew = cn({
      [styles.button]: true,
      [styles[size]]: true,
      [styles[variant]]: true,
      [styles.disabled]: disabled,
      [className]: className,
    });

    const newProps = {
      ...props,
      className: classNameNew,
      disabled,
    };
    const Comp = asChild ? Slot : 'button';
    return <Comp {...newProps} ref={ref} />;
  }
);
