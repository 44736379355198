import React from 'react';
import createIcon from '../utils/createIcon';

const PencilIcon = createIcon(
  <>
    <path
      d="M16.1549 10.59L13.4099 7.84497"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.68825 18.75H5.25V16.3117C5.25 16.113 5.32875 15.9217 5.46975 15.7815L15.4703 5.78024C15.7635 5.48699 16.2383 5.48699 16.5308 5.78024L18.219 7.46849C18.5123 7.76174 18.5123 8.23649 18.219 8.52899L8.2185 18.5302C8.07825 18.6712 7.887 18.75 7.68825 18.75V18.75Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>,
  'Pencil'
);

export default PencilIcon;
