// extracted by mini-css-extract-plugin
export var additionalInfo = "index-module--additionalInfo--424a8";
export var anim = "index-module--anim--74c9e";
export var blueCircle = "index-module--blueCircle--2f3e7";
export var childrenAndAdditionalInfo = "index-module--childrenAndAdditionalInfo--d06ac";
export var childrenContainer = "index-module--childrenContainer--85f02";
export var cirlceBlock = "index-module--cirlceBlock--c5383";
export var description = "index-module--description--d4264";
export var iconContainer = "index-module--iconContainer--2edf9";
export var img = "index-module--img--5505f";
export var large = "index-module--large--1f08a";
export var lightCircle = "index-module--lightCircle--228e1";
export var title = "index-module--title--d58a2";
export var titleAndDescription = "index-module--titleAndDescription--c6142";
export var wrapper = "index-module--wrapper--e49eb";
export var xLarge = "index-module--xLarge--43129";