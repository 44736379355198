import React from 'react';
import cn from 'classnames';
import propTypes from 'prop-types';
import styles from './SwitchButtons.module.scss';

function SendToButton({ iconUrl, active, header, description, onSelect, customClassName = '' }) {
  return (
    <button
      type="button"
      className={cn({
        [styles.button]: true,
        [styles.active]: active,
        [`${customClassName}-button`]: !!customClassName,
        [`${customClassName}-button--active`]: !!customClassName && active,
      })}
      onClick={onSelect}
    >
      <img alt="icon" className={styles.icon} src={iconUrl} />
      <p className={styles.p2}>{header}</p>
      <div className={styles.gift}>{description}</div>
    </button>
  );
}

SendToButton.propTypes = {
  header: propTypes.oneOfType([propTypes.string, propTypes.node]),
  description: propTypes.oneOfType([propTypes.string, propTypes.node]),
  active: propTypes.bool,
  iconUrl: propTypes.string.isRequired,
  onSelect: propTypes.func.isRequired,
  customClassName: propTypes.string,
};

SendToButton.defaultProps = {
  active: false,
  header: '',
  description: '',
  customClassName: '',
};

export function SwitchButtons({ value, onChange, info, customClassName }) {
  const onChangeHandler = (v) => () => {
    if (onChange !== null) {
      onChange(v);
    }
  };

  return (
    <div
      className={cn({
        [styles.switch_wrapper]: true,
        [styles.one_tab]: info.length === 1,
        [customClassName]: !!customClassName,
      })}
    >
      {info.map((i, idx) => {
        if (idx > 2) return null;
        return (
          <SendToButton
            onSelect={onChangeHandler(i.value)}
            active={value === i.value}
            iconUrl={value === i.value ? i.activePic : i.disablePic}
            header={i.header}
            description={i.description}
            customClassName={customClassName}
            key={i.value}
          />
        );
      })}
    </div>
  );
}

SwitchButtons.propTypes = {
  value: propTypes.oneOfType([propTypes.string, propTypes.number]),
  onChange: propTypes.func,
  info: propTypes.arrayOf(
    propTypes.shape({
      value: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
      activePic: propTypes.string.isRequired,
      disablePic: propTypes.string.isRequired,
      header: propTypes.oneOfType([propTypes.string, propTypes.node]).isRequired,
      description: propTypes.oneOfType([propTypes.string, propTypes.node]),
    })
  ).isRequired,
  customClassName: propTypes.string,
};

SwitchButtons.defaultProps = {
  value: 0,
  onChange: null,
  customClassName: '',
};
