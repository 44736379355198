import React from 'react';
import cn from 'classnames';

export type AsChildProps<ComponentProps, DefaultElementProps> =
  | ({ asChild?: false } & DefaultElementProps)
  | ({ asChild: true } & ComponentProps);

export function Slot(props: React.HTMLAttributes<HTMLElement>) {
  const { children, ...slotProps } = props;
  if (React.isValidElement(children)) {
    const child = React.Children.only(children) as React.ReactElement;
    return React.cloneElement(child, {
      ...slotProps,
      ...children.props,
      style: {
        ...slotProps.style,
        ...children.props.style,
      },
      className: cn([slotProps.className, children.props.className]),
    }) as any;
  }
  if (React.Children.count(children) > 1) {
    throw new Error('Only one child allowed');
  }
  return null;
}
