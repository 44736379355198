import React from 'react';
import createIcon from '../utils/createIcon';

const BalloonClose = createIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
    <path
      d="M1.125 0.936523L9.875 9.68652"
      stroke="#C4C4C4"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M1.125 9.68652L9.875 0.936523"
      stroke="#C4C4C4"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>,
  'BalloonClose'
);

export default BalloonClose;
