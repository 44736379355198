import React from 'react';
import cn from 'classnames';

import * as styles from './index.module.css';

export type CounterProps = { value: number; className?: string };

export function Counter({ value, className }: CounterProps) {
  return <div className={cn(className, styles.counter)}>{value}</div>;
}
