import React from 'react';
import createIcon from '../utils/createIcon';

/* eslint-disable react/no-unknown-property */
const TipDndIcon = createIcon(
  <>
    <rect x="0.5" y="9.5" width="22" height="31" fill="white" stroke="#CCCCCC" />
    <rect x="23" y="9" width="23" height="32" className="tip-dnd-shadow" />
    <rect x="31" y="1" width="23" height="32" fill="white" strokeWidth="0.75" />
    <circle cx="42.5005" cy="16.5" r="5.5" />
    <rect x="86" y="9" width="46" height="32" className="tip-dnd-shadow" />
    <rect x="96" y="1" width="23" height="32" fill="white" strokeWidth="0.75" />
    <rect x="119" y="1" width="23" height="32" fill="white" strokeWidth="0.75" />
    <circle cx="119" cy="17.5" r="6.5" />
  </>,
  'TipDnd'
);
/* eslint-enable react/no-unknown-property */

export default TipDndIcon;
