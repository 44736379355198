import React from 'react';
import createIcon from '../utils/createIcon';

const CartIcon = createIcon(
  <>
    <path d="M14 15.98V16.99" strokeWidth="1.5024" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 15.98V16.99" strokeWidth="1.5024" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M20 12H4C3.448 12 3 11.552 3 11V9C3 8.448 3.448 8 4 8H20C20.552 8 21 8.448 21 9V11C21 11.552 20.552 12 20 12Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.04 3.03003L17 7.97003"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.96 3.03003L7 7.97003"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.7582 12L18.0712 19.789C17.9222 20.479 17.3112 20.971 16.6052 20.971H7.39519C6.68919 20.971 6.07919 20.479 5.92919 19.789L4.24219 12"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>,
  'Cart'
);

export default CartIcon;
