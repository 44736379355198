import React from 'react';
import cn from 'classnames';
import Icon from '../assets/images/icon-review.svg';
import * as styles from './index.module.css';

type TitleSize = 'xLarge' | 'large';

type EmptyLayoutArgs = {
  hasIcon?: boolean;
  title: string;
  titleSize?: TitleSize;
  description?: React.ReactNode;
  additionalInfo?: React.ReactNode;
};
export type EmptyLayoutProps = React.PropsWithChildren<EmptyLayoutArgs>;

export function EmptyLayout({
  hasIcon = true,
  title,
  titleSize = 'xLarge',
  description,
  additionalInfo,
  children,
}: EmptyLayoutProps) {
  return (
    <div className={styles.wrapper}>
      {hasIcon && (
        <div className={styles.iconContainer}>
          <div className={styles.cirlceBlock}>
            <div className={styles.lightCircle}>
              <div className={styles.blueCircle}>
                <img className={styles.img} src={Icon} alt="" />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.titleAndDescription}>
        <h1 className={cn([styles.title, styles[titleSize]])}>{title}</h1>
        {description ? <div className={styles.description}>{description}</div> : null}
      </div>
      <div className={styles.childrenAndAdditionalInfo}>
        {children ? <div className={styles.childrenContainer}>{children}</div> : null}
        {additionalInfo ? <div className={styles.additionalInfo}>{additionalInfo}</div> : null}
      </div>
    </div>
  );
}
