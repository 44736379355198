import React, { useState, useMemo, useCallback, useEffect, forwardRef } from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';
import { matchSorter } from 'match-sorter';
import * as Ariakit from '@ariakit/react';

import { ComboboxPopover } from './components/ComboboxPopover';

import styles from './Combobox.module.scss';

export const Combobox = forwardRef(function Combobox(
  {
    values,
    onChange,
    value,
    variant,
    label,
    subtitle,
    disabled,
    placeholder,
    customClassName = '',
  },
  ref
) {
  const [searchValue, setSearchValue] = useState('');
  const comboboxStore = Ariakit.useComboboxStore();
  const isPopoverOpen = comboboxStore.useState('open');
  const comboboxMoves = comboboxStore.useState('moves');

  const selectedValue = useMemo(() => values.find((item) => item.value === value), [value, values]);

  const matches = useMemo(() => !selectedValue || (selectedValue && searchValue !== selectedValue.title)
      ? matchSorter(values, searchValue, {
          keys: ['title'],
          threshold: matchSorter.rankings.CONTAINS,
        })
      : values, [selectedValue, searchValue, values]);

  const comboboxItems = searchValue.length ? matches : values;

  const handleChangeSearch = (val) => {
    if (typeof val === 'object') {
      setSearchValue(val?.title);
    } else {
      setSearchValue(val);
    }
  };

  const handleSelectValue = useCallback(
    (val) => {
      onChange(val.value);
      setSearchValue(val.title);
    },
    [onChange]
  );

  const handleGetAutoSelectId = useCallback(
    (items) => {
      const itemToAutoSelect = items.find((item) => item.value.value === value);

      if (!itemToAutoSelect && searchValue.length && searchValue !== selectedValue?.title) {
        return items[0]?.id;
      }

      return itemToAutoSelect?.id;
    },
    [searchValue, selectedValue?.title, value]
  );

  useEffect(() => {
    if (!isPopoverOpen && searchValue !== selectedValue?.title && comboboxMoves) {
      setSearchValue(selectedValue?.title || '');
    }
  }, [comboboxMoves, isPopoverOpen, searchValue, selectedValue]);

  useEffect(() => {
    if (selectedValue) {
      handleChangeSearch(selectedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  return (
    <Ariakit.ComboboxProvider
      store={comboboxStore}
      value={searchValue}
      setValue={handleChangeSearch}
      selectedValue={value}
      setSelectedValue={handleSelectValue}
      focusLoop={false}
    >
      <div
        className={cn({
          [styles.comboboxWrapper]: true,
          [customClassName]: !!customClassName,
          [styles.disabled]: disabled,
        })}
      >
        <Ariakit.ComboboxLabel
          className={cn({
            [styles.label]: true,
            [`${customClassName}--label`]: !!customClassName,
          })}
        >
          {label}
        </Ariakit.ComboboxLabel>
        <div
          className={cn({
            [styles.comboboxContainer]: true,
            [`${customClassName}--container`]: !!customClassName,
          })}
        >
          <Ariakit.Combobox
            className={cn({
              [styles.input]: true,
              [styles.valueSelected]: !!value && !isPopoverOpen,
              [`${customClassName}--input`]: !!customClassName,
              [styles[`${variant}-variant`]]: !!variant,
            })}
            placeholder={placeholder}
            disabled={disabled}
            autoSelect="always"
            getAutoSelectId={handleGetAutoSelectId}
            ref={ref}
          />
          <Ariakit.ComboboxDisclosure className={styles.disclosure} disabled={disabled} />
        </div>
        <Ariakit.ComboboxLabel
          className={cn({
            [styles.subtitle]: true,
            [`${customClassName}--subtitle`]: !!customClassName,
          })}
        >
          {subtitle}
        </Ariakit.ComboboxLabel>
        <ComboboxPopover
          customClassName={customClassName}
          items={comboboxItems}
          selectedItem={value}
        />
      </div>
    </Ariakit.ComboboxProvider>
  );
});

Combobox.propTypes = {
  values: propTypes.arrayOf(
    propTypes.shape({
      value: propTypes.oneOfType([propTypes.string, propTypes.number]),
      title: propTypes.string,
    })
  ).isRequired,
  onChange: propTypes.func.isRequired,
  value: propTypes.oneOfType([propTypes.string, propTypes.number]),
  variant: propTypes.oneOf(['default', 'white']),
  label: propTypes.string,
  subtitle: propTypes.string,
  customClassName: propTypes.string,
  disabled: propTypes.bool,
  placeholder: propTypes.string,
};

Combobox.defaultProps = {
  variant: 'default',
  label: '',
  subtitle: '',
  customClassName: '',
  placeholder: 'Подпись',
  disabled: false,
};
