import React from 'react';
import createIcon from '../utils/createIcon';

const PlusIcon = createIcon(
  <g>
    <path d="M11.9998 21.0005L11.9998 2.9993" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M21.0003 11.9999L2.99916 11.9999" strokeWidth="1.5" strokeLinecap="round" />
  </g>,
  'Plus'
);

export default PlusIcon;
