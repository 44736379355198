import React from 'react';
import createIcon from '../utils/createIcon';

const UndoIcon = createIcon(
  <g>
    <path
      d="M12.8339 9.04457H12.8439C17.7339 9.59457 21.4239 13.7246 21.4339 18.6446V19.2546H21.4439C19.3239 16.6946 16.1939 15.1846 12.8639 15.1346V18.3546L12.8739 18.3446C12.8739 19.0146 12.3339 19.5646 11.6539 19.5646C11.3839 19.5546 11.1139 19.4646 10.9039 19.2946L2.91591 12.9836V12.9736C2.40591 12.5636 2.32591 11.8236 2.72591 11.3136C2.78591 11.2336 2.85591 11.1736 2.92591 11.1136L10.9159 4.80357L10.9259 4.79357C11.4559 4.37357 12.2259 4.46357 12.6459 4.99357C12.8259 5.20357 12.9159 5.47357 12.9159 5.74357L12.8339 9.04457Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>,
  'Undo'
);

export default UndoIcon;
