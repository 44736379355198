import React from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';
import arrow from './down-arrow.svg';
import styles from './Dropdown.module.scss';

export function Dropdown({
  title,
  customClassName,
  children,
  onHover,
  onMouseLeave,
  onClickOutside,
  isOpen,
}) {
  const dropdownRef = React.useRef(null);

  const handleClickOutside = React.useCallback(
    (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClickOutside();
      }
    },
    [onClickOutside]
  );

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return (
    <div
      tabIndex="0"
      className={cn({
        [styles.dropdownWrapper]: true,
        [customClassName]: !!customClassName,
      })}
      onFocus={onHover}
      onMouseEnter={onHover}
      onMouseLeave={onMouseLeave}
    >
      <div
        className={cn({
          [styles.dropdownButton]: true,
          [`${customClassName}--button`]: !!customClassName,
        })}
      >
        {title}
        <img src={arrow} className={cn({ [styles.active]: isOpen })} alt="arrow" />
      </div>
      <div
        className={cn({
          [styles.dropdownContent]: true,
          [styles.showContent]: isOpen,
          [`${customClassName}--content`]: !!customClassName,
        })}
        ref={dropdownRef}
      >
        {children}
      </div>
    </div>
  );
}

Dropdown.propTypes = {
  title: propTypes.string.isRequired,
  customClassName: propTypes.string,
  children: propTypes.oneOfType([propTypes.arrayOf(propTypes.node), propTypes.node]).isRequired,
  onHover: propTypes.func.isRequired,
  onMouseLeave: propTypes.func.isRequired,
  isOpen: propTypes.bool.isRequired,
  onClickOutside: propTypes.func.isRequired,
};

Dropdown.defaultTypes = {
  customClassName: '',
};
