import React, { type ReactNode } from 'react';
import cn from 'classnames';
import { StatusMessage } from '../StatusMessage';
import { Paragraph as P } from '../Paragraph';
import * as styles from './styles.module.css';

type FormFieldArgs = {
  label?: string;
  error?: ReactNode | string | undefined;
  description?: ReactNode | string | undefined;
  name?: string;
  disabled?: boolean;
  className?: string;
  tagElement?: 'label' | 'div';
  style?: React.CSSProperties;
};

export type FormFieldProps = React.PropsWithChildren<FormFieldArgs> &
  React.RefAttributes<HTMLDivElement>;

export const FormField = React.forwardRef<HTMLDivElement, FormFieldProps>(
  (
    {
      error,
      label,
      description,
      name,
      children,
      disabled,
      className = '',
      tagElement = 'label',
      style = {},
    },
    ref
  ) => {
    const Comp = tagElement;
    return (
      <div
        className={cn([styles.formField, disabled && styles.disabled, className])}
        style={style}
        ref={ref}
      >
        {label && (
          <Comp className={styles.label} htmlFor={name}>
            {label}
          </Comp>
        )}
        {children}
        {description && (
          <P size="small" color="secondary">
            {description}
          </P>
        )}
        {error && <StatusMessage>{error}</StatusMessage>}
      </div>
    );
  }
);
