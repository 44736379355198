import React, { useCallback, useMemo, useRef } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.css';
import mergeRefs from '../utils/mergeRefs';
import { Cross as CloseIcon } from '../../icons';

export type TextFieldNewArgs = {
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  error?: string | boolean;
  readOnly?: boolean;
  style?: React.CSSProperties;
  tagElement?: 'input' | 'textarea';
  value?: string;
  customEmptyValue?: string;
  onChange?: (e: string) => void;
};

function ClearButton({ onClick }: { onClick: () => void }) {
  return (
    <button
      type="button"
      onClick={onClick}
      aria-label="Очистить поле ввода"
      className={styles.clearButton}
    >
      <div className={styles.iconContainer}>
        <CloseIcon />
      </div>
    </button>
  );
}

export type TextFieldNewProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement> & React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  'onChange'
> &
  TextFieldNewArgs &
  React.RefAttributes<HTMLInputElement | HTMLTextAreaElement>;

export const TextFieldNew = React.forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  TextFieldNewProps
>(
  (
    {
      className = '',
      defaultValue,
      disabled,
      error,
      readOnly,
      tagElement = 'input',
      value,
      style,
      customEmptyValue,
      onChange,
      ...otherProps
    },
    ref
  ) => {
    const fieldRef = useRef<HTMLInputElement>(null);
    const fieldEl = useMemo(() => mergeRefs([fieldRef, ref]), [ref]);

    const Comp = tagElement;

    const handleChange = useCallback(
      (e) => {
        const newValue = e.target.value;
        onChange && onChange(newValue);
      },
      [onChange]
    );

    const handleClear = useCallback(() => {
      // fix for phone field
      const emptyValue =
        customEmptyValue && value?.startsWith(customEmptyValue) ? customEmptyValue : '';
      onChange && onChange(emptyValue);
      fieldRef?.current && fieldRef.current.focus();
    }, [onChange, value, customEmptyValue]);

    const classNameWrapper = cn([
      tagElement !== 'textarea' ? styles.inputWrapper : styles.textareaWrapper,
      className,
    ]);

    const hasError = Boolean(error);
    const classNameInput = cn([
      styles.input,
      tagElement === 'textarea' && styles.textarea,
      hasError && styles.error,
      disabled && styles.disabled,
      readOnly && styles.readOnly,
    ]);

    const showClearButton =
      tagElement === 'input' &&
      value !== customEmptyValue && // fix for phone field
      ((value === undefined && defaultValue) || value) &&
      !disabled &&
      !readOnly;

    return (
      <div className={classNameWrapper} style={style}>
        <Comp
          /* eslint-disable react/jsx-props-no-spreading */
          {...(value !== undefined ? { value } : {})}
          {...(defaultValue !== undefined ? { defaultValue } : {})}
          {...(tagElement === 'textarea' ? { rows: otherProps.rows || 3 } : {})}
          {...otherProps}
          /* eslint-enable react/jsx-props-no-spreading */
          ref={fieldEl}
          onChange={handleChange}
          className={classNameInput}
          disabled={disabled}
          readOnly={readOnly}
        />
        {showClearButton && <ClearButton onClick={handleClear} />}
      </div>
    );
  }
);
