import React from 'react';
import cn from 'classnames';

import { Paragraph as P } from '../../Paragraph';
import { MapPin, Clock } from '../../../icons';
import ClosePopupIcon from '../../Icons/ClosePopupIcon';

import styles from './Balloon.module.scss';

interface BalloonArgs {
  provider: string;
  title: string;
  openingHours: string;
  howToGet: string;
  fullscreenMap: boolean;
  onClose: () => void;
}

export function Balloon({
  provider,
  title,
  openingHours,
  howToGet,
  fullscreenMap,
  onClose,
}: BalloonArgs) {
  return (
    <div
      className={cn({
        [styles.balloonMain]: true,
        [styles.fullBalloon]: fullscreenMap,
      })}
    >
      <div className={styles.metadata}>
        <P size="large" weight="emphasized">
          {provider}
        </P>
        <div className={styles.infoBlock}>
          <div className={styles.infoLine}>
            <MapPin />
            <P>{title}</P>
          </div>
          <div className={styles.infoLine}>
            <Clock />
            <P>{openingHours}</P>
          </div>
        </div>
        {howToGet && (
          <div className={styles.route}>
            <P size="small" color="secondary">
              Как добраться
            </P>
            <P className={styles.mainRoute}>{howToGet}</P>
          </div>
        )}
      </div>
      <button className={styles.closeButtonContainer} onClick={onClose} type="button">
        <div className={styles.closeButton}>
          <ClosePopupIcon />
        </div>
      </button>
    </div>
  );
}
