import React from 'react';

import cn from 'classnames';
import { Portal } from '@ariakit/react';
import { Toaster, toast } from 'react-hot-toast';
import { Cross } from '@periodica/ui-kit/icons';

import * as styles from './styles.module.css';

function ToastContent({
  children,
  onDismiss,
}: {
  children: React.ReactNode;
  onDismiss: (id: any) => void;
}) {
  return (
    <div className={styles.toastContent}>
      <span>{children}</span>
      <div className={styles.iconBlock}>
        <Cross width={20} height={20} onClick={onDismiss} className={styles.toastIcon} />
      </div>
    </div>
  );
}

type NotifyType = 'success' | 'error';

export function notify({ text, type = 'success' }: { text: string; type?: NotifyType }) {
  switch (type) {
    case 'error':
      toast.error((t) => <ToastContent onDismiss={() => toast.dismiss(t.id)}>{text}</ToastContent>);
      break;
    default:
      toast.success((t) => (
        <ToastContent onDismiss={() => toast.dismiss(t.id)}>{text}</ToastContent>
      ));
  }
}

export const ToastContentClassName = 'ToastsContainer';
export function ToastsContainer() {
  return (
    <Portal render={<div className={ToastContentClassName} />}>
      <Toaster
        containerStyle={{
          inset: '8px',
          zIndex: 99999,
        }}
        toastOptions={{
          duration: 5000,
          removeDelay: 250,
          className: styles.toast,
          success: {
            className: cn([styles.toast, styles.success]),
            position: 'bottom-center',
            icon: null,
          },
          error: {
            className: cn([styles.toast, styles.error]),
            position: 'top-center',
            icon: null,
          },
        }}
      />
    </Portal>
  );
}
