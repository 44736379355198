import React from 'react';
import createIcon from '../utils/createIcon';

const ShoppingCartIcon = createIcon(
  <>
    <path d="M67.6536 95.6751C51.7978 102.575 34.5008 124.323 8.02019 100.851C-18.4604 77.3784 25.1494 -6.49826 72.9463 0.402119C120.743 7.3025 130.227 15.2338 130.227 60.5097C130.227 82.7828 83.5094 88.7754 67.6536 95.6751Z" />
    <path
      d="M44.6063 107.06C49.1385 107.06 52.8125 103.364 52.8125 98.8058C52.8125 94.2471 49.1385 90.5515 44.6063 90.5515C40.0741 90.5515 36.4 94.2471 36.4 98.8058C36.4 103.364 40.0741 107.06 44.6063 107.06Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M99.125 107.06C103.657 107.06 107.331 103.364 107.331 98.8058C107.331 94.2471 103.657 90.5515 99.125 90.5515C94.5928 90.5515 90.9188 94.2471 90.9188 98.8058C90.9188 103.364 94.5928 107.06 99.125 107.06Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.9563 4.98535H24.7813L38.064 71.7376C38.5173 74.0328 39.7586 76.0945 41.5708 77.5619C43.383 79.0292 45.6506 79.8087 47.9765 79.7639H96.1513C98.4773 79.8087 100.745 79.0292 102.557 77.5619C104.369 76.0945 105.611 74.0328 106.064 71.7376L113.994 29.9115H29.7375"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>,
  'ShoppingCart'
);

export default ShoppingCartIcon;
