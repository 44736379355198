import React from 'react';
import createIcon from '../utils/createIcon';

const PlusInCircleIcon = createIcon(
  <g>
    <circle opacity="0.25" cx="32" cy="32" r="31.5" />
    <path d="M32 41.0006L32 22.9994" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M41.0006 32L22.9994 32" strokeWidth="1.5" strokeLinecap="round" />
  </g>,
  'PlusInCircle'
);

export default PlusInCircleIcon;
