import React from 'react';
import cn from 'classnames';
import { Slot, type AsChildProps } from '../Slot';
import * as styles from './index.module.css';

type VariantType = 'brandPrimary' | 'primary';

export type LinkArgs = {
  variant?: VariantType;
  className?: string;
};

export type LinkProps = AsChildProps<
  React.PropsWithChildren<LinkArgs>,
  React.AnchorHTMLAttributes<HTMLAnchorElement> & LinkArgs
> &
  React.RefAttributes<HTMLAnchorElement>;

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ asChild, variant = 'brandPrimary', className = '', ...props }, ref) => {
    const classNameNew = cn({
      [styles.link]: true,
      [styles[variant]]: true,
      [className]: className,
    });

    const newProps = {
      ...props,
      className: classNameNew,
    };
    const Comp = asChild ? Slot : 'a';
    return <Comp {...newProps} ref={ref} />;
  }
);
