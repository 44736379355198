import React from 'react';
import createIcon from '../utils/createIcon';

const TextMinusIcon = createIcon(
  <g>
    <path d="M8.24658 4H21.8066" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.9795 20V4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2 13H10" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </g>,
  'TextMinus'
);

export default TextMinusIcon;
