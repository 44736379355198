import React from 'react';
import cn from 'classnames';
import { Paragraph as P } from '../Paragraph';
import { Alert as ErrorIcon } from '../../icons';

import * as styles from './styles.module.css';

type StatusMessageArgs = {
  variant?: 'critical';
  className?: string;
};

export type StatusMessageProps = React.PropsWithChildren<StatusMessageArgs>;

export function StatusMessage({
  children,
  className = '',
  variant = 'critical',
}: StatusMessageProps) {
  return (
    <div className={cn([styles.statusMessage, styles[variant], className])}>
      <ErrorIcon size={16} />
      <P size="small">{children}</P>
    </div>
  );
}
