import React from 'react';
import createIcon from '../utils/createIcon';

const BinIcon = createIcon(
  <g>
    <path
      d="M18 6V18.75C18 19.993 16.973 21 15.731 21H8.231C6.988 21 6 19.993 6 18.75V6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M19.5 6H4.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 3H14" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 10V17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 17V10" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </g>,
  'Bin'
);

export default BinIcon;
