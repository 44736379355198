import React, { forwardRef, type CSSProperties, type ReactElement } from 'react';
import cn from 'classnames';
import { Question } from '@periodica/ui-kit/icons';
import * as styles from './index.module.css';

export type HelpButtonProps = {
  onClick?: any;
  children?: ReactElement | null;
  loading?: boolean;
  style?: CSSProperties;
  className?: string;
};

export const HelpButton = forwardRef<HTMLButtonElement, HelpButtonProps>(function HelpButton(
  { onClick, children, loading, className = '', ...props },
  ref
) {
  return (
    <button
      ref={ref}
      className={cn({
        [styles.helpButton]: true,
        [className]: className,
      })}
      disabled={loading ? true : undefined}
      type="button"
      onClick={onClick}
      {...props}
    >
      <Question size={20} />
      {children}
    </button>
  );
});
