import type { PickupPointType } from './types';

export const getMinMaxGeo = (points: PickupPointType[]) => {
  if (!points.length) {
    return {
      lat: {
        min: 0,
        max: 0,
      },
      lng: {
        min: 0,
        max: 0,
      },
    };
  }

  let minLat = points[0].lat;
  let maxLat = points[0].lat;
  let minLng = points[0].lng;
  let maxLng = points[0].lng;

  for (let i = 0; i < points.length; i += 1) {
    if (points[i].lat < minLat) minLat = points[i].lat;
    if (points[i].lat > maxLat) maxLat = points[i].lat;
    if (points[i].lng < minLng) minLng = points[i].lng;
    if (points[i].lng > maxLng) maxLng = points[i].lng;
  }

  return {
    lat: {
      min: minLat,
      max: maxLat,
    },
    lng: {
      min: minLng,
      max: maxLng,
    },
  };
};
