import React from 'react';
import cn from 'classnames';
import { Paragraph as P } from '../Paragraph';
import * as styles from './styles.module.css';

type ControlLabelArgs = {
  label?: string;
  description?: string;
  inputName?: string;
  disabled?: boolean;
  size?: 'medium' | 'large';
  className?: string;
  style?: React.CSSProperties;
};

export type ControlLabelProps = React.PropsWithChildren<ControlLabelArgs>;

export function ControlLabel({
  label,
  description,
  inputName,
  children,
  disabled,
  size = 'large',
  className = '',
  style = {},
}: ControlLabelProps) {
  return (
    <label
      className={cn([styles.controlLabel, disabled && styles.disabled, className])}
      style={style}
      htmlFor={inputName}
    >
      {label && (
        <div className={cn([styles.label, styles[size]])}>
          <div className={styles.controlContainer}>{children}</div>
          {label}
        </div>
      )}
      {description && (
        <P size="small" color="secondary" className={styles.description}>
          {description}
        </P>
      )}
    </label>
  );
}
