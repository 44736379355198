import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './SvgIcon.scss';

function SvgIcon(props) {
  const { children, className, color, fontSize, viewBox, styles, ...other } = props;

  const classes = cn({
    'svg-icon-base': true,
    [`svg-icon-color--${color}`]: true,
    [`svg-icon-size--${fontSize}`]: true,
    ...(className ? { [className]: true } : {}),
  });

  return (
    <svg
      width="1em"
      height="1em"
      fill="transparent"
      stroke="currentColor"
      viewBox={viewBox}
      className={classes}
      style={styles}
      {...other}
    >
      {children}
    </svg>
  );
}

SvgIcon.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  fontSize: PropTypes.oneOf(['small', 'medium', 'large']),
  viewBox: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  styles: PropTypes.object, // TODO: rename to style
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

SvgIcon.defaultProps = {
  color: 'primary',
  fontSize: 'medium',
  viewBox: '0 0 24 24',
  styles: {},
  className: '',
};

SvgIcon.displayName = 'SvgIcon';

export default SvgIcon;
