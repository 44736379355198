import React from 'react';
import * as Ariakit from '@ariakit/react';
import cn from 'classnames';
import propTypes from 'prop-types';

import { ComboboxItem } from '../ComboboxItem';

import styles from './ComboboxPopover.module.scss';

export function ComboboxPopover({ items, customClassName, selectedItem }) {
  return (
    <Ariakit.ComboboxPopover
      sameWidth
      className={cn({
        [styles.popover]: true,
        [styles.popoverAnimation]: true,
        [`${customClassName}--popover`]: !!customClassName,
      })}
      portal
      flip={false}
    >
      <div className={styles.popoverContent}>
        {items.length ? (
          items.map((item) => (
              <ComboboxItem key={item.value} value={item} selected={item.value === selectedItem} />
            ))
        ) : (
          <div
            className={cn({
              [styles.notFound]: true,
              [`${customClassName}--notFound`]: !!customClassName,
            })}
          >
            Нет результатов...
          </div>
        )}
      </div>
    </Ariakit.ComboboxPopover>
  );
}

ComboboxPopover.propTypes = {
  items: propTypes.arrayOf(
    propTypes.shape({
      value: propTypes.oneOfType([propTypes.string, propTypes.number]),
      title: propTypes.string,
    })
  ).isRequired,
  customClassName: propTypes.string,
  selectedItem: propTypes.number,
};

ComboboxPopover.defaultProps = {
  customClassName: '',
};
