import React from 'react';
import createIcon from '../utils/createIcon';

const ThreeDotsIcon = createIcon(
  <g>
    <path
      d="M18.5 12.0001C18.5 12.2701 18.27 12.5001 18 12.5001C17.72 12.5001 17.5 12.2701 17.5 12.0001C17.5 11.7201 17.72 11.5001 18 11.5001H17.99C18.26 11.4901 18.49 11.7201 18.49 11.9901"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 12.0001C12.5 12.2701 12.27 12.5001 12 12.5001C11.72 12.5001 11.5 12.2701 11.5 12.0001C11.5 11.7201 11.72 11.5001 12 11.5001H11.99C12.26 11.4901 12.49 11.7201 12.49 11.9901"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 12.0001C6.5 12.2701 6.27 12.5001 6 12.5001C5.72 12.5001 5.5 12.2701 5.5 12.0001C5.5 11.7201 5.72 11.5001 6 11.5001H5.99C6.26 11.4901 6.49 11.7201 6.49 11.9901"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>,
  'ThreeDots'
);

export default ThreeDotsIcon;
