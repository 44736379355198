import React from 'react';
import createIcon from '../utils/createIcon';

const QuestionIcon = createIcon(
  <>
    <path
      d="M12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.67992 9.67995H9.66992C9.89992 8.64995 10.8299 7.94995 11.8799 7.98995H11.8699C13.0399 7.91995 14.0399 8.80995 14.1199 9.97995C14.1199 11.4799 11.9699 11.9799 11.9699 12.9799"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.125 15.75C12.125 15.81 12.065 15.875 12 15.875C11.93 15.875 11.875 15.815 11.875 15.75C11.875 15.68 11.925 15.625 12 15.625"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0002 15.6251H11.9902C12.0502 15.6151 12.1102 15.6751 12.1102 15.7451"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>,
  'Question'
);

export default QuestionIcon;
