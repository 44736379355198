import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useYMaps } from '@pbe/react-yandex-maps';
import { PROVIDERS_NAMES } from '@periodica/consts';

import { Loader } from '../../Loader/Loader';
import { Balloon } from '../Balloon';

import {
  ACTIVE_ICON_SIZE,
  ACTIVE_ICON_OFFSET,
  DEFAULT_ICON_SIZE,
  DEFAULT_ICON_OFFSET,
  ZOOM_MIN_VALUE,
  ZOOM_MAX_VALUE,
} from '../consts';
import { getMinMaxGeo } from '../utils';

import type { PickupPointsMapArgs, PickupPointType } from '../types';

import Default from '../assets/Default.svg';
import Boxberry from '../assets/Boxberry.svg';
import Cdek from '../assets/Cdek.svg';
import FivePost from '../assets/FivePost.svg';
import Mail from '../assets/Mail.svg';

import styles from './VanillaMapContainer.module.scss';

const PROVIDERS_ICONS = {
  BOXBERRY: Boxberry,
  CDEK: Cdek,
  FIVE_POST: FivePost,
  MAIL: Mail,
};

export function VanillaMapContainer({
  selectedPickupPoint,
  onSelect,
  points,
  loadingText = 'Загружаем карту...',
  useZoomOnPointsUpdate = false,
  hasFullscreenControl = false,
  dropSelectedAfterBalloonClose = false,
}: PickupPointsMapArgs) {
  const ymaps = useYMaps([
    'Map',
    'Clusterer',
    'Placemark',
    'control.Manager',
    'control.FullscreenControl',
    'control.ZoomControl',
  ]);

  const [isBalloonOpen, setIsBalloonOpen] = useState(false);
  const [isFullscreenMap, setIsFullscreenMap] = useState(false);
  const [previousValue, setPreviousValue] = useState<PickupPointType | null>(null);

  const { lat, lng } = useMemo(() => getMinMaxGeo(points), [points]);

  const mapRef = useRef<HTMLDivElement | null>(null);
  const currentPlacemark = useRef<ymaps.Placemark>();
  const map = useRef<ymaps.Map>();
  const placemarksArrayRef = useRef<ymaps.Placemark[]>([]);
  const fullscreenControl = useRef<ymaps.control.FullscreenControl>();
  const zoomControl = useRef<ymaps.control.ZoomControl>();
  const clusterer = useRef<ymaps.Clusterer>();

  const handleCloseBalloon = () => {
    setIsBalloonOpen(false);
    if (dropSelectedAfterBalloonClose && currentPlacemark.current) {
      currentPlacemark.current.options.set({
        iconImageSize: DEFAULT_ICON_SIZE,
        iconImageOffset: DEFAULT_ICON_OFFSET,
      });
    }
  };

  useEffect(() => {
    if (!ymaps || !mapRef.current || !points.length) {
      return;
    }
    // ymap handlers
    const handleFullscreenEnter = () => {
      setIsFullscreenMap(true);
    };

    const handleFullscreenExit = () => {
      setIsFullscreenMap(false);
    };

    const handleBoundsChange = (e) => {
      const [newLat, newLng] = e.get('newGlobalPixelCenter');
      const [oldLat, oldLng] = e.get('oldGlobalPixelCenter');

      if (
        (Math.abs(newLat - oldLat) > 250 || Math.abs(newLng - oldLng) > 250) &&
        e.get('newZoom') === e.get('oldZoom')
      ) {
        handleCloseBalloon();
      }
    };

    const handleSelectPoint = (e) => {
      currentPlacemark.current?.options.set({
        iconImageSize: DEFAULT_ICON_SIZE,
        iconImageOffset: DEFAULT_ICON_OFFSET,
      });

      const selectedPointData = e?.originalEvent?.target?.properties?.getAll();
      currentPlacemark.current = e?.originalEvent?.target;

      currentPlacemark.current?.options.set({
        iconImageSize: ACTIVE_ICON_SIZE,
        iconImageOffset: ACTIVE_ICON_OFFSET,
      });

      if (!onSelect) {
        return;
      }

      setPreviousValue(selectedPointData);
      onSelect(selectedPointData);
      setIsBalloonOpen(true);
    };

    // map utils
    const getCenterAndZoom = () => {
      const offsetCoef = 0.003;
      const { center, zoom } = ymaps.util.bounds.getCenterAndZoom(
        [
          [lat.min - offsetCoef, lng.min - offsetCoef],
          [lat.max + offsetCoef, lng.max + offsetCoef],
        ],
        [mapRef.current?.clientWidth || 320, mapRef.current?.clientHeight || 640]
      );

      let newZoom = zoom;
      if (zoom > ZOOM_MAX_VALUE) newZoom = ZOOM_MAX_VALUE;
      if (zoom < ZOOM_MIN_VALUE) newZoom = ZOOM_MIN_VALUE;

      return {
        center: center.flat(),
        zoom: newZoom,
      };
    };

    const getPlacemarksArray = () => {
      const placemarksArray: ymaps.Placemark[] = [];
      for (let i = 0; i < points.length; i += 1) {
        placemarksArray[i] = new ymaps.Placemark(
          [points[i].lat, points[i].lng],
          { ...points[i] },
          {
            iconLayout: 'default#image',
            iconImageHref: PROVIDERS_ICONS[points[i].deliveryProviderName] || Default,
            iconImageSize:
              selectedPickupPoint?.id === points[i].id ? ACTIVE_ICON_SIZE : DEFAULT_ICON_SIZE,
            iconImageOffset:
              selectedPickupPoint?.id === points[i].id ? ACTIVE_ICON_OFFSET : DEFAULT_ICON_OFFSET,
          }
        );
        placemarksArray[i].events.add('click', handleSelectPoint);
      }
      return placemarksArray;
    };

    const removePlacemarkEvents = (placemarks: ymaps.Placemark[]) => {
      placemarks.map((object) => object.events.remove('click', handleSelectPoint));
    };

    if (!map.current) {
      const { center, zoom } = getCenterAndZoom();
      map.current = new ymaps.Map(mapRef.current, {
        center,
        zoom,
        margin: [40, 40],
      });

      // add controls
      if (hasFullscreenControl) {
        fullscreenControl.current = new ymaps.control.FullscreenControl();
        fullscreenControl.current.events.add('fullscreenenter', handleFullscreenEnter);
        fullscreenControl.current.events.add('fullscreenexit', handleFullscreenExit);
        map.current.controls.add(fullscreenControl.current);
      }

      zoomControl.current = new ymaps.control.ZoomControl({
        options: {
          position: { right: '10px', top: hasFullscreenControl ? '60px' : '10px' },
        },
      });

      map.current.controls.add(zoomControl.current);

      // add clusterer and points
      clusterer.current = new ymaps.Clusterer({
        gridSize: 100,
        maxZoom: 15,
        preset: 'islands#nightClusterIcons',
      });

      // map events
      map.current.events.add('click', handleCloseBalloon);
      map.current.events.add('boundschange', handleBoundsChange);

      placemarksArrayRef.current = getPlacemarksArray();
      clusterer.current.add(placemarksArrayRef.current);
      // @ts-ignore
      map.current.geoObjects.add(clusterer.current);

      if (selectedPickupPoint) {
        map.current?.setCenter([selectedPickupPoint.lat, selectedPickupPoint.lng]);
        map.current?.setZoom(18);
      }
    } else {
      // if points will update
      handleCloseBalloon();
      removePlacemarkEvents(placemarksArrayRef.current);
      placemarksArrayRef.current = getPlacemarksArray();

      clusterer.current?.removeAll();
      clusterer.current?.add(placemarksArrayRef.current);
      // @ts-ignore
      map.current.geoObjects.add(clusterer.current);

      if (useZoomOnPointsUpdate) {
        const { center, zoom } = getCenterAndZoom();
        map.current.setCenter(center);
        map.current.setZoom(zoom);
      }
    }

    return () => {
      map.current?.events.remove('click', handleCloseBalloon);
      map.current?.events.remove('boundschange', handleBoundsChange);

      if (hasFullscreenControl) {
        fullscreenControl.current?.events.remove('fullscreenenter', handleFullscreenEnter);
        fullscreenControl.current?.events.remove('fullscreenexit', handleFullscreenExit);
      }

      removePlacemarkEvents(placemarksArrayRef.current);
      currentPlacemark.current?.events.remove('click', handleSelectPoint);
      clusterer.current?.removeAll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ymaps, points, lat, lng]);

  useEffect(() => {
    if (
      selectedPickupPoint &&
      (selectedPickupPoint?.id !== previousValue?.id || !currentPlacemark.current)
    ) {
      map.current?.setCenter([selectedPickupPoint.lat, selectedPickupPoint.lng]);
      map.current?.setZoom(18);

      if (!currentPlacemark.current) {
        const currPlacemark = placemarksArrayRef.current.find((point) => {
          const currData = point.properties.getAll();
          // @ts-ignore
          return previousValue?.id === currData?.id;
        });
        currentPlacemark.current = currPlacemark;
      }

      currentPlacemark.current?.options.set({
        iconImageSize: DEFAULT_ICON_SIZE,
        iconImageOffset: DEFAULT_ICON_OFFSET,
      });

      const nextPlacemark = placemarksArrayRef.current.find((point) => {
        const currData = point.properties.getAll();
        // @ts-ignore
        return selectedPickupPoint?.id === currData?.id;
      });
      currentPlacemark.current = nextPlacemark;
      currentPlacemark.current?.options.set({
        iconImageSize: ACTIVE_ICON_SIZE,
        iconImageOffset: ACTIVE_ICON_OFFSET,
      });
      setPreviousValue(selectedPickupPoint);
    }
    // it shouldn't update, when previousValue changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPickupPoint]);

  return (
    <div className={styles.mapWrapper}>
      <div className={styles.loaderWrapper}>
        <Loader />
        <p className={styles.loaderText}>{loadingText}</p>
      </div>
      <div ref={mapRef} className={styles.mapContainer} />
      {selectedPickupPoint && selectedPickupPoint?.id === previousValue?.id && isBalloonOpen && (
        <Balloon
          provider={PROVIDERS_NAMES[selectedPickupPoint.deliveryProviderName]}
          title={selectedPickupPoint.title}
          openingHours={selectedPickupPoint.openingHours}
          howToGet={selectedPickupPoint.howToGet}
          fullscreenMap={isFullscreenMap}
          onClose={handleCloseBalloon}
        />
      )}
    </div>
  );
}
