import React from 'react';
import createIcon from '../utils/createIcon';

const BurgerMenuIcon = createIcon(
  <g>
    <path d="M3.5 12H20.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.5 18H20.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.5 6H20.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </g>,
  'BurgerMenu'
);

export default BurgerMenuIcon;
