import React from 'react';
import SvgIcon from '../SvgIcon';

const createIcon = (path, displayName) => {
  function Component(props) {
    return <SvgIcon {...props}>{path}</SvgIcon>;
  }

  Component.displayName = `${displayName}Icon`;

  return Component;
};

export default createIcon;
