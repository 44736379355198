import React from 'react';
import createIcon from '../utils/createIcon';

const TemplatesIcon = createIcon(
  <>
    <path d="M12.0098 3.03003V21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 10.22H21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M12.0095 14.72H3.01953"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 3H19C20.105 3 21 3.895 21 5V19C21 20.105 20.105 21 19 21H5C3.895 21 3 20.105 3 19V5C3 3.895 3.895 3 5 3Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>,
  'Templates'
);

export default TemplatesIcon;
