import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';
import mergeRefs from '../utils/mergeRefs';
import styles from './SelectField.module.scss';

const DATE_TYPES = ['date', 'time'];

export const SelectField = React.forwardRef(function TextField(
  {
    type,
    description,
    error,
    name,
    label,
    icon,
    values,
    value,
    defaultValue,
    inputProps,
    onChange,
    onClick,
    onKeyDown,
    onBlur,
    button,
    customClassName = '',
  },
  ref
) {
  const [state, setState] = useState({
    focused: false,
    touched: false,
  });

  const inputRef = useRef();
  const inputEl = useMemo(() => mergeRefs([inputRef, ref]), [ref]);

  useEffect(() => {
    setState((prev) => ({ ...prev, touched: !!inputRef.current.value || value }));
  }, [inputRef, state.focused, value]);

  const handleFocus = useCallback(() => {
    setState((prev) => ({ ...prev, focused: true }));
  }, []);

  const handleBlur = useCallback(() => {
    if (onBlur) {
      onBlur();
    }
    setState((prev) => ({ ...prev, focused: false }));
  }, [onBlur]);

  const handleChange = useCallback((e) => onChange(e.target.value), [onChange]);

  const handleClick = useCallback(() => onClick(), [onClick]);

  const handleOnKeyDown = useCallback((e) => onKeyDown(e), [onKeyDown]);

  return (
    <div
      className={cn({
        [styles.root]: true,
        [customClassName]: !!customClassName,
      })}
    >
      <label
        className={cn({
          [styles.label]: true,
          [styles.focused]: state.focused,
          [styles.touched]: DATE_TYPES.includes(type) || state.touched,
          [styles.error]: error,
          [`${customClassName}-label-focused`]: !!customClassName && state.focused,
          [`${customClassName}-label-touched`]:
            (!!customClassName && DATE_TYPES.includes(type)) || state.touched,
          [`${customClassName}-label-error`]: !!customClassName && error,
        })}
        htmlFor={name}
      >
        {label}
      </label>
      <div className={styles.inputWrapper}>
        <div
          className={cn({
            [styles.formInput]: true,
            [styles.focused]: state.focused,
            [styles.errror]: error,
            [`${customClassName}-form-input`]: !!customClassName,
            [`${customClassName}-form-input--focused`]: !!customClassName && state.focused,
            [`${customClassName}-form-input--error`]: !!customClassName && error,
          })}
        >
          <select
            /* eslint-disable react/jsx-props-no-spreading */
            {...inputProps}
            {...(onChange ? { onChange: handleChange } : {})}
            {...(onClick ? { onClick: handleClick } : {})}
            {...(value !== undefined ? { value } : {})}
            {...(defaultValue !== undefined ? { defaultValue } : {})}
            {...(onKeyDown ? { onKeyDown: handleOnKeyDown } : {})}
            /* eslint-enable react/jsx-props-no-spreading */
            ref={inputEl}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={styles.input}
            type={type}
            name={name}
          >
            {values.map((s) => (
              <option value={s.value} key={s.id}>
                {s.text}
              </option>
            ))}
          </select>

          {icon && <span className={styles.icon}>{icon}</span>}
        </div>
        {button && <div className={styles.buttonWrapper}>{button}</div>}
      </div>
      {error && <div className={styles.errorLabel}>{error}</div>}
      {description && <div className={styles.description}>{description}</div>}
    </div>
  );
});

SelectField.propTypes = {
  type: propTypes.string,
  description: propTypes.string,
  label: propTypes.string,
  name: propTypes.string.isRequired,
  value: propTypes.oneOfType([propTypes.string, propTypes.number]),
  onChange: propTypes.func,
  onClick: propTypes.func,
  onKeyDown: propTypes.func,
  onBlur: propTypes.func,
  icon: propTypes.node,
  button: propTypes.node,
  error: propTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: propTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  values: propTypes.array,
  defaultValue: propTypes.oneOfType([propTypes.string, propTypes.number]),
  customClassName: propTypes.string,
};

SelectField.defaultProps = {
  type: 'text',
  description: undefined,
  error: undefined,
  label: '',
  inputProps: {},
  values: [],
  defaultValue: undefined,
  value: undefined,
  onChange: undefined,
  onClick: undefined,
  onKeyDown: undefined,
  onBlur: undefined,
  icon: undefined,
  button: undefined,
  customClassName: '',
};
