import React from 'react';
import cn from 'classnames';
import propTypes from 'prop-types';

import { ToggleButton } from './components';

import styles from './ToggleButtons.module.scss';

export function ToggleButtons({ value, onChange, values, customClassName }) {
  const handleClick = (val) => {
    if (onChange) {
      onChange(val);
    }
  };

  return (
    <div
      className={cn({
        [styles.container]: true,
        [styles.oneItem]: values.length === 1,
        [customClassName]: !!customClassName,
      })}
    >
      {values.map((item) => (
          <ToggleButton
            key={`item_${item.value}`}
            onClick={() => handleClick(item.value)}
            active={value === item.value}
            iconUrl={value === item.value ? item.activePic : item.disablePic}
            title={item.title}
            subtitle={item.subtitle}
          />
        ))}
    </div>
  );
}

ToggleButtons.propTypes = {
  value: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
  onChange: propTypes.func,
  values: propTypes.arrayOf(
    propTypes.shape({
      value: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
      activePic: propTypes.string,
      disablePic: propTypes.string,
      title: propTypes.oneOfType([propTypes.string, propTypes.node]).isRequired,
      subtitle: propTypes.oneOfType([propTypes.string, propTypes.node]),
    })
  ).isRequired,
  customClassName: propTypes.string,
};

ToggleButtons.defaultProps = {
  onChange: () => undefined,
};
