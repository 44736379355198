import React from 'react';
import createIcon from '../utils/createIcon';

const PhotoIcon = createIcon(
  <g>
    <path
      d="M21 16.014L16.707 11.721C16.316 11.33 15.683 11.33 15.293 11.721L11 16.014"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 21.0139H6C4.343 21.0139 3 19.6709 3 18.0139V6.01392C3 4.35692 4.343 3.01392 6 3.01392H18C19.657 3.01392 21 4.35692 21 6.01392V18.0139C21 19.6709 19.657 21.0139 18 21.0139Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 21.014L9.70698 14.721C9.31598 14.33 8.68298 14.33 8.29298 14.721L3.44598 19.568"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="9" cy="9" r="1" />
  </g>,
  'Photo'
);

export default PhotoIcon;
