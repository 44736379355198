import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';
import mergeRefs from '../utils/mergeRefs';
import styles from './InputButton.module.scss';

export const InputButton = React.forwardRef(function InputButton(
  { description, error, name, icon, value, defaultValue, buttonProps, onClick, onKeyDown, onBlur },
  ref
) {
  const [state, setState] = useState({
    focused: false,
    touched: false,
  });

  const inputRef = useRef(null);
  const inputEl = useMemo(() => mergeRefs([inputRef, ref]), [ref]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      touched: (inputRef && inputRef.current && !!inputRef.current.value) || value || defaultValue,
    }));
  }, [inputRef, defaultValue, state.focused, value]);

  const handleFocus = useCallback(() => {
    setState((prev) => ({ ...prev, focused: true }));
  }, []);

  const handleBlur = useCallback(() => {
    if (onBlur) {
      onBlur();
    }
    setState((prev) => ({ ...prev, focused: false }));
  }, [onBlur]);

  const handleClick = useCallback(() => onClick(), [onClick]);

  const handleOnKeyDown = useCallback((e) => onKeyDown(e), [onKeyDown]);

  return (
    <div className={styles.root}>
      <div className={styles.buttonWrapper}>
        <div
          className={cn({
            [styles.formButton]: true,
            [styles.focused]: state.focused,
            [styles.error]: error,
          })}
          /* eslint-disable react/jsx-props-no-spreading */
          {...(onClick ? { onClick: handleClick } : {})}
        >
          <button
            {...buttonProps}
            {...(defaultValue !== undefined ? { defaultValue } : {})}
            {...(onKeyDown ? { onKeyDown: handleOnKeyDown } : {})}
            /* eslint-enable react/jsx-props-no-spreading */
            ref={inputEl}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={styles.button}
            name={name}
            type="button"
          >
            {value}
          </button>
          {icon && <span className={styles.icon}>{icon}</span>}
        </div>
      </div>
      {error && <div className={styles.errorLabel}>{error}</div>}
      {description && <div className={styles.description}>{description}</div>}
    </div>
  );
});

InputButton.displayName = 'InputButton';

InputButton.propTypes = {
  description: propTypes.string,
  name: propTypes.string.isRequired,
  value: propTypes.oneOfType([propTypes.string, propTypes.number, propTypes.object]),
  onClick: propTypes.func,
  onKeyDown: propTypes.func,
  onBlur: propTypes.func,
  icon: propTypes.node,
  error: propTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  buttonProps: propTypes.object,
  defaultValue: propTypes.string,
};

InputButton.defaultProps = {
  description: undefined,
  error: undefined,
  buttonProps: {},
  defaultValue: undefined,
  value: undefined,
  onClick: undefined,
  onKeyDown: undefined,
  onBlur: undefined,
  icon: undefined,
};
