import React from 'react';
import createIcon from '../utils/createIcon';

const DiscountIcon = createIcon(
  <>
    <path
      d="M9.89905 14.1L14.099 9.89999"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.48201 19.741C9.77701 19.702 10.075 19.782 10.31 19.963L11.393 20.794C11.751 21.069 12.249 21.069 12.606 20.794L13.73 19.931C13.94 19.77 14.205 19.699 14.467 19.734L15.873 19.919C16.32 19.978 16.751 19.729 16.924 19.312L17.465 18.004C17.566 17.759 17.76 17.565 18.005 17.464L19.313 16.923C19.73 16.751 19.979 16.319 19.92 15.872L19.742 14.517C19.703 14.222 19.783 13.924 19.964 13.689L20.795 12.606C21.07 12.248 21.07 11.75 20.795 11.393L19.932 10.269C19.771 10.059 19.7 9.79401 19.735 9.53201L19.92 8.12601C19.979 7.67901 19.73 7.24801 19.313 7.07501L18.005 6.53401C17.76 6.43301 17.566 6.23901 17.465 5.99401L16.924 4.68601C16.752 4.26901 16.32 4.02001 15.873 4.07901L14.467 4.26401C14.205 4.30001 13.94 4.22901 13.731 4.06901L12.607 3.20601C12.249 2.93101 11.751 2.93101 11.394 3.20601L10.27 4.06901C10.06 4.22901 9.79501 4.30001 9.53301 4.26601L8.12701 4.08101C7.68001 4.02201 7.24901 4.27101 7.07601 4.68801L6.53601 5.99601C6.43401 6.24001 6.24001 6.43401 5.99601 6.53601L4.68801 7.07601C4.27101 7.24901 4.02201 7.68001 4.08101 8.12701L4.26601 9.53301C4.30001 9.79501 4.22901 10.06 4.06901 10.269L3.20601 11.393C2.93101 11.751 2.93101 12.249 3.20601 12.606L4.06901 13.73C4.23001 13.94 4.30101 14.205 4.26601 14.467L4.08101 15.873C4.02201 16.32 4.27101 16.751 4.68801 16.924L5.99601 17.465C6.24101 17.566 6.43501 17.76 6.53601 18.005L7.07701 19.313C7.24901 19.73 7.68101 19.979 8.12801 19.92L9.48201 19.741"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="10" cy="10" r="1" />
    <circle cx="14" cy="14" r="1" />
  </>,
  'Discount'
);

export default DiscountIcon;
