import React, { forwardRef } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.css';

type VariantType = 'h1' | 'h2' | 'h3';
type CustomTagType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p';

type HeadingArgs = {
  variant?: VariantType;
  customTag?: CustomTagType;
  className?: string;
};
export type HeadingProps = React.PropsWithChildren<HeadingArgs> &
  React.ComponentProps<'h1'> &
  React.RefAttributes<HTMLHeadingElement>;

export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ children, variant = 'h1', customTag, className, ...props }, ref) => {
    const Comp = customTag || variant;
    return (
      <Comp className={cn(styles.h, styles[variant], className)} {...props} ref={ref}>
        {children}
      </Comp>
    );
  }
);
