import React from 'react';
import createIcon from '../utils/createIcon';

const TruckIcon = createIcon(
  <>
    <path
      d="M17.704 14.296C18.323 14.915 18.323 15.918 17.704 16.536C17.085 17.155 16.082 17.155 15.464 16.536C14.845 15.917 14.845 14.914 15.464 14.296C16.083 13.677 17.086 13.677 17.704 14.296Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.704 14.296C7.323 14.915 7.323 15.918 6.704 16.536C6.085 17.155 5.082 17.155 4.464 16.536C3.846 15.917 3.845 14.914 4.464 14.296C5.083 13.678 6.085 13.677 6.704 14.296Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 1.8V11.2C1 11.6418 1.35817 12 1.8 12H13.2C13.6418 12 14 11.6418 14 11.2V1.8C14 1.35817 13.6418 1 13.2 1H1.8C1.35817 1 1 1.35817 1 1.8Z"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M4 15.416H2C1.448 15.416 1 14.968 1 14.416V9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 3H18.323C18.732 3 19.1 3.249 19.251 3.629L20.857 7.643C20.951 7.879 21 8.131 21 8.385V14.333C21 14.885 20.552 15.333 20 15.333H18.169"
      stroke="#FFFFFF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M15 15.42H7.17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <g filter="url(#filter0_d)">
      <path d="M21 10H17V6H20.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="12.25"
        y="5.25"
        width="13.5"
        height="13.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </>,
  'Truck'
);

export default TruckIcon;
