// extracted by mini-css-extract-plugin
export var brandPrimary = "styles-module--brandPrimary--23be2";
export var brandSecondary = "styles-module--brandSecondary--b7d5d";
export var button = "styles-module--button--c283d";
export var disabled = "styles-module--disabled--9fb84";
export var large = "styles-module--large--d19dc";
export var medium = "styles-module--medium--1fc8c";
export var secondary = "styles-module--secondary--7ce3e";
export var secondaryOverlay = "styles-module--secondaryOverlay--5fb5e";
export var small = "styles-module--small--391f8";
export var tertiary = "styles-module--tertiary--bfc3c";
export var x2Large = "styles-module--x2Large--48d0d";
export var xLarge = "styles-module--xLarge--c5eb9";