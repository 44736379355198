import React from 'react';
import createIcon from '../utils/createIcon';

const BigCloseIcon = createIcon(
  <g>
    <path d="M6.25 6.25L23.75 23.75" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M6.25 23.75L23.75 6.25" strokeWidth="1.5" strokeLinecap="round" />
  </g>,
  'BigClose'
);

export default BigCloseIcon;
