import React from 'react';
import createIcon from '../utils/createIcon';

const CheckSquareIcon = createIcon(
  <g>
    <path
      d="M17 21C19.2 21 21 19.2 21 17V14V7C21 4.79 19.2 3 17 3H7C4.79 3 3 4.79 3 7V17C3 19.2 4.79 21 7 21H17Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 7L13.8768 10L12 8.18919"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>,
  'CheckSquare'
);

export default CheckSquareIcon;
