export const ORIENTATION_SQUARE = 'square';
export const ORIENTATION_VERTICAL = 'vertical';
export const ORIENTATION_HORIZONTAL = 'horizontal';

export const PAYMENT_TYPE_CARD = 'card';
export const PAYMENT_TYPE_CASH = 'cash';
export const PAYMENT_TYPE_SPLIT = 'split';
export const PAYMENT_TYPE_SBP = 'sbp';

export const DELIVERY_TYPE_PICKUP = '0';
export const DELIVERY_TYPE_COURIER = '1';

export const CITY_ID_MOSCOW = 2;

export const DELIVERY_PICKUP_PRICE = 230;
export const DELIVERY_COURIER_PRICE = 390;

export const PROVIDERS_NAMES: { [key: string]: any } = {
  BOXBERRY: 'Boxberry',
  CDEK: 'CDEK',
  FIVE_POST: '5Post',
  MAIL: 'Почта России',
};

export const APP_VERSION = process.env.APP_VERSION || 6;

export const GIFT_BOX_PRICE = 490;
export const PIXELS_IN_MILLIMETER = 2.876;

// export const FORM_CHECKOUT_ORDER_NAME = 'checkout';
// export const FORM_CHECKOUT_GIFT_NAME = 'gift_checkout';

export const DUMMY_TEXT = 'Ваш текст здесь';
export const DUMMY_TEXT_SMALL = 'Ваш текст';

export const COMPONENT_STATUS_LOADING = 'loading';
export const COMPONENT_STATUS_IDLE = 'idle';

export const TYPE_RECIPIENT_EMAIL = 1;
export const TYPE_OWN_EMAIL = 2;

export const PROJECT_STATUS_DRAFT = 'draft';
export const PROJECT_STATUS_CART = 'cart';

export const YANDEX_MAP_API_KEY = process.env.YANDEX_MAP_API_KEY || '';
export const YANDEX_CAPTCHA_CLIENT_KEY = process.env.YANDEX_CAPTCHA_CLIENT_KEY || '';

export const FEEDBACK_PERIOD_WAS_SUBMITTED = 30;
export const FEEDBACK_PERIOD_WAS_CANCELED = 3;

export enum RequestStatus {
  Idle,
  Fetching,
  Success,
  Error,
}

export const DEFAULT_PROJECT_TITLE = 'Без названия';
export const MAX_PROJECT_TITLE_LENGTH = 50;
