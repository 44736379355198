import React from 'react';
import createIcon from '../utils/createIcon';

const MinusIcon = createIcon(
  <g>
    <path d="M6 12H18" strokeLinecap="square" strokeLinejoin="round" />
  </g>,
  'Minus'
);

export default MinusIcon;
