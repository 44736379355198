import React from 'react';
import createIcon from '../utils/createIcon';

const MagicWandIcon = createIcon(
  <g>
    <path d="M20 5V3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19 4H21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.87001 20.705L3.29501 19.13C2.90101 18.736 2.90101 18.098 3.29501 17.705L12.87 8.12997C13.264 7.73597 13.902 7.73597 14.295 8.12997L15.87 9.70497C16.264 10.099 16.264 10.737 15.87 11.13L6.29501 20.705C5.90201 21.098 5.26301 21.098 4.87001 20.705Z"
      strokeWidth="1.5104"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5099 13.31L10.6799 10.48"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M21 15H19" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20 14V16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 3V5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 4H8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </g>,
  'MagicWand'
);

export default MagicWandIcon;
