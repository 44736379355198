import React from 'react';
import createIcon from '../utils/createIcon';

const FolderIcon = createIcon(
  <path
    d="M19.0005 6.93999H12.5295C12.1895 6.93999 11.8885 6.76999 11.7025 6.49999L10.2925 4.42999C10.1025 4.14999 9.79247 3.98999 9.46247 3.98999H4.98047C3.87547 3.98999 2.98047 4.87999 2.98047 5.98999V17.99C2.98047 19.09 3.87547 19.99 4.98047 19.99H18.9805C20.0805 19.99 20.9805 19.09 20.9805 17.99V8.92999C20.9805 7.82599 20.0805 6.92999 18.9805 6.92999L19.0005 6.93999Z"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  'Folder'
);

export default FolderIcon;
