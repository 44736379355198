import React from 'react';
import cn from 'classnames';
import propTypes from 'prop-types';
import Checkmark from './Checkmark.svg';
import CheckmarkFilter from './CheckmarkFilter.svg';
import './CheckboxField.scss';

export function CheckboxField({ title, description, onChange, value, checked, disabled, filter }) {
  return (
    <div
      className={cn({
        checkbox: true,
        filter,
      })}
    >
      <label
        className={cn({
          label: true,
          mediumFont: filter,
        })}
      >
        <div
          className={cn({
            inputBlock: true,
            disabled,
          })}
        >
          <input
            className={cn({
              input: true,
              small: filter,
            })}
            type="checkbox"
            onChange={onChange}
            value={value}
            {...(checked ? { checked } : {})}
            {...(disabled ? { disabled } : {})}
          />

          <span
            className={cn({
              checkboxImg: true,
              small: filter,
            })}
          >
            <img src={filter ? CheckmarkFilter : Checkmark} alt="checked" />
          </span>
          {title}
        </div>
        <p
          className={cn({
            checkbox_description: true,
            mediumFont: filter,
          })}
        >
          {description}
        </p>
      </label>
    </div>
  );
}

CheckboxField.propTypes = {
  title: propTypes.string.isRequired,
  description: propTypes.string,
  onChange: propTypes.func,
  checked: propTypes.bool,
  disabled: propTypes.bool,
  filter: propTypes.bool,
  value: propTypes.string,
};
CheckboxField.defaultProps = {
  description: '',
  onChange: () => {},
  checked: false,
  disabled: false,
  filter: false,
  value: '',
};
