import React from 'react';
import createIcon from '../utils/createIcon';

const BalloonClock = createIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_4801_30827)">
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        stroke="#2747AE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6.75V12H17.25"
        stroke="#2747AE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4801_30827">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  'BalloonClock'
);

export default BalloonClock;
