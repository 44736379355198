import React from 'react';
import createIcon from '../utils/createIcon';

const TwoStripesIcon = createIcon(
  <g>
    <path d="M21 6.5L1 6.5" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M21 1L1 1" strokeWidth="1.5" strokeLinecap="round" />
  </g>,
  'TwoStripes'
);

export default TwoStripesIcon;
