import { useMaskito } from '@maskito/react';
import React, { forwardRef, useMemo, type ComponentProps } from 'react';
import { TextFieldNew, type TextFieldNewProps } from '../TextFieldNew';
import mergeRefs from '../utils/mergeRefs';
import options, { phonePrefix } from './mask';

export const PhoneFieldNew = forwardRef<HTMLInputElement, TextFieldNewProps>((props, ref) => {
  const maskedInputRef = useMaskito({ options });
  const inputRef = useMemo(() => mergeRefs([maskedInputRef, ref]), [maskedInputRef, ref]);

  return (
    <TextFieldNew
      ref={inputRef}
      inputMode="tel"
      onInput={(e) => props.onChange?.(e.currentTarget.value)}
      customEmptyValue={phonePrefix}
      {...props}
    />
  );
});

export type PhoneFieldNewProps = ComponentProps<typeof PhoneFieldNew>;
