import React from 'react';
import createIcon from '../utils/createIcon';

const AddPhotosIcon = createIcon(
  <>
    <path
      d="M11.8381 14.3642V14.3516V14.3389C11.8887 14.2758 11.8887 14.1747 11.8255 14.1116C11.7624 14.0484 11.6613 14.0484 11.5981 14.1116C11.535 14.1621 11.535 14.2632 11.5981 14.3263C11.6487 14.3768 11.7497 14.3768 11.8129 14.3137"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 15.9692V15.9566C1.36632 15.8934 1.7579 15.8555 2.13684 15.8555H2.12421C6.53263 15.8429 10.0947 19.4176 10.0947 23.826V23.8134C10.0821 24.1923 10.0568 24.5713 9.99369 24.9502"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6316 8.57886H4.78947C2.69661 8.57886 1 10.2755 1 12.3683V21.2104C1 23.3033 2.69661 24.9999 4.78947 24.9999H13.6316C15.7245 24.9999 17.4211 23.3033 17.4211 21.2104V12.3683C17.4211 10.2755 15.7245 8.57886 13.6316 8.57886Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9473 3.5271H24.9999"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M22.4736 6.05263V1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M22.4735 9.84131V17.4203C22.4735 19.5045 20.7683 21.2097 18.6841 21.2097H17.4209"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.78962 8.57967V7.31651C4.77699 5.21967 6.48225 3.5144 8.56646 3.5144H16.1454"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>,
  'AddPhotos'
);

export default AddPhotosIcon;
