import React from 'react';
import createIcon from '../utils/createIcon';

const ReceiptIcon = createIcon(
  <>
    <path d="M16 7H8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11 11H8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 15H8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M17 22C14.239 22 12 19.762 12 17C12 14.296 14.3 11.997 17.004 12C19.764 12.002 22 14.24 22 17C22 19.761 19.762 22 17 22Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 13.022V4C20 3.448 19.552 3 19 3H5C4.448 3 4 3.448 4 4V19.191C4 19.563 4.391 19.804 4.724 19.638L6.667 18.666L9.11 19.888C9.251 19.958 9.416 19.958 9.557 19.888L12 18.667L12.362 18.848"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M19 17H15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17 19V15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </>,
  'Receipt'
);

export default ReceiptIcon;
