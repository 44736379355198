import React from 'react';
import createIcon from '../utils/createIcon';

const CloseIcon = createIcon(
  <g id="final" strokeWidth="1" fillRule="evenodd" opacity="0.300000012">
    <g id="2" transform="translate(-1256.000000, -186.000000)">
      <g
        id="cross"
        transform="translate(1264.142136, 194.142136) rotate(45.000000) translate(-1264.142136, -194.142136) translate(1254.142136, 184.142136)"
      >
        <rect id="Rectangle" x="9" y="0" width="2" height="20" />
        <rect
          id="Rectangle"
          transform="translate(10.000000, 10.000000) rotate(90.000000) translate(-10.000000, -10.000000) "
          x="9"
          y="0"
          width="2"
          height="20"
        />
      </g>
    </g>
  </g>,
  'Close'
);

export default CloseIcon;
