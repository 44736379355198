// extracted by mini-css-extract-plugin
export var emphasized = "styles-module--emphasized--df3b1";
export var inherit = "styles-module--inherit--8a1a6";
export var large = "styles-module--large--a5767";
export var medium = "styles-module--medium--f997b";
export var normal = "styles-module--normal--1882d";
export var p = "styles-module--p--f8a05";
export var primary = "styles-module--primary--3ea8a";
export var primaryDisabled = "styles-module--primaryDisabled--0e8f7";
export var secondary = "styles-module--secondary--629f7";
export var small = "styles-module--small--0f43c";