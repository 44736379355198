import React from 'react';
import createIcon from '../utils/createIcon';

const CloseIcon = createIcon(
  <>
    <path d="M11.5 15.6399H5.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.5 18.6399V12.6399" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M2.5 20.3571V10.6421C2.5 10.0901 2.948 9.64209 3.5 9.64209H13.5C14.052 9.64209 14.5 10.0901 14.5 10.6421V20.3571C14.5 20.9091 14.052 21.3571 13.5 21.3571H3.5C2.948 21.3571 2.5 20.9101 2.5 20.3571Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 9.64307V3.64307C8.5 3.09107 8.948 2.64307 9.5 2.64307H20.5C21.052 2.64307 21.5 3.09107 21.5 3.64307V14.6431C21.5 15.1951 21.052 15.6431 20.5 15.6431H14.5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>,
  'Close'
);

export default CloseIcon;
