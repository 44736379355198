import React from 'react';
import createIcon from '../utils/createIcon';

const GridIcon = createIcon(
  <g>
    <path d="M3 3H10V10H3V3Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 3H21V10H14V3Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 14H10V21H3V14Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 14H21V21H14V14Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </g>,
  'Grid'
);

export default GridIcon;
