import React from 'react';
import { YMaps } from '@pbe/react-yandex-maps';

import { VanillaMapContainer } from './VanillaMapContainer';
import type { PickupPointsMapArgs } from './types';

export function PickupPointsMap(props: PickupPointsMapArgs) {
  return (
    <YMaps query={{ apikey: props.yandexApiKey, load: 'util.bounds' }}>
      <VanillaMapContainer {...props} />
    </YMaps>
  );
}
