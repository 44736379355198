import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.css';

type WeightType = 'normal' | 'emphasized';
type SizeType = 'small' | 'medium' | 'large' | 'inherit';
type ColorType = 'primary' | 'secondary' | 'primaryDisabled';

type ParagraphArgs = {
  weight?: WeightType;
  size?: SizeType;
  color?: ColorType;
  className?: string;
};
export type ParagraphProps = React.PropsWithChildren<ParagraphArgs> & React.ComponentProps<'p'>;

export function Paragraph({
  children,
  weight = 'normal',
  size = 'medium',
  color,
  className = '',
  ...props
}: ParagraphProps) {
  return (
    <p
      className={cn(styles.p, styles[weight], styles[size], color && styles[color], className)}
      {...props}
    >
      {children}
    </p>
  );
}
