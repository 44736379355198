import React from 'react';
import createIcon from '../utils/createIcon';

const BagIcon = createIcon(
  <g>
    <path
      d="M16 10V6C16 3.791 14.209 2 12 2C9.791 2 8 3.791 8 6V10"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 21H5C4.448 21 4 20.552 4 20V8C4 7.448 4.448 7 5 7H19C19.552 7 20 7.448 20 8V20C20 20.552 19.552 21 19 21Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>,
  'Bag'
);

export default BagIcon;
