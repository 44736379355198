import React from 'react';
import cn from 'classnames';
import propTypes from 'prop-types';

import styles from './ToggleButton.module.scss';

export function ToggleButton({ onClick, active, iconUrl, title, subtitle, customClassName }) {
  return (
    <button
      type="button"
      className={cn({
        [styles.button]: true,
        [styles.active]: active,
        [`${customClassName}-button`]: !!customClassName,
        [`${customClassName}-button--active`]: !!customClassName && active,
      })}
      onClick={onClick}
    >
      <img alt="icon" className={styles.icon} src={iconUrl} />
      <p className={styles.title}>{title}</p>
      <div className={styles.subtitle}>{subtitle}</div>
    </button>
  );
}

ToggleButton.propTypes = {
  onClick: propTypes.func.isRequired,
  active: propTypes.bool.isRequired,
  iconUrl: propTypes.string.isRequired,
  title: propTypes.oneOfType([propTypes.string, propTypes.node]).isRequired,
  subtitle: propTypes.oneOfType([propTypes.string, propTypes.node]),
  customClassName: propTypes.string,
};
