import { Loading as LoadingIcon } from '@periodica/ui-kit/icons';
import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { Button, type ButtonArgs, type ButtonProps } from '../Button';
import { IconButton } from '../IconButton';
import * as styles from './styles.module.css';

export type LoaderButtonProps = React.PropsWithChildren<
  {
    loading?: boolean;
  } & ButtonArgs &
    React.ButtonHTMLAttributes<HTMLButtonElement> &
    React.RefAttributes<HTMLButtonElement>
>;

const buttonSizeToIconSize = (buttonSize: ButtonArgs['size']): number => {
  switch (buttonSize) {
    case 'small':
      return 16;

    case 'medium':
      return 20;

    default:
      return 24;
  }
};

const LoaderButtonImplementation = forwardRef<
  HTMLButtonElement,
  LoaderButtonProps & { buttonComponent: React.FC<ButtonProps> }
>((props, ref) => {
  const { variant, size, disabled, children, loading, className, buttonComponent, ...buttonProps } =
    props;
  const Comp = buttonComponent;
  return (
    <Comp asChild variant={variant} size={size} disabled={disabled}>
      <button
        ref={ref}
        disabled={disabled || loading}
        className={classNames([styles.loaderButton, className])}
        type="button"
        {...buttonProps}
      >
        <span className={classNames(styles.state, loading && styles.visuallyHidden)}>
          {children}
        </span>

        <span className={classNames(styles.loader, styles.state, loading || styles.visuallyHidden)}>
          <LoadingIcon
            size={buttonSizeToIconSize(size)}
            className={classNames(styles.icon, loading && styles.spin)}
          />
        </span>
      </button>
    </Comp>
  );
});

export const LoaderButton = forwardRef<HTMLButtonElement, LoaderButtonProps>((props, ref) => (
  <LoaderButtonImplementation {...props} buttonComponent={Button} ref={ref} />
));

export const LoaderIconButton = forwardRef<HTMLButtonElement, LoaderButtonProps>((props, ref) => (
  <LoaderButtonImplementation {...props} buttonComponent={IconButton} ref={ref} />
));
