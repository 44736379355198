import React from 'react';
import createIcon from '../utils/createIcon';

const CloudSaveIcon = createIcon(
  <>
    <path
      d="M11.9985 11.833L11.9985 18.833"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1665 16.833L11.9995 19L9.8325 16.833"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0002 19H18.5602C20.4802 19 22.0602 17.42 22.0602 15.5C22.0602 13.57 20.4802 12 18.5602 12H18.1202V11C18.1202 7.69 15.4302 5 12.1202 5C9.14022 5 6.67022 7.178 6.20022 10.023C3.82322 10.143 1.92822 12.093 1.92822 14.5C1.92822 16.98 3.93822 19 6.42822 19H7.98822"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>,
  'CloudSave'
);

export default CloudSaveIcon;
