import React from 'react';
import createIcon from '../utils/createIcon';

const CashIcon = createIcon(
  <g>
    <path
      d="M12 13.9097C13.1046 13.9097 14 13.0142 14 11.9097C14 10.8051 13.1046 9.90967 12 9.90967C10.8954 9.90967 10 10.8051 10 11.9097C10 13.0142 10.8954 13.9097 12 13.9097Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.804 18.2129C17.299 17.7119 14.715 17.7739 12.237 18.3929L12 18.4529C9.366 19.1109 6.619 19.1769 3.957 18.6439L3.804 18.6129C3.336 18.5199 3 18.1089 3 17.6329V6.7939C3 6.1629 3.577 5.6899 4.196 5.8129C6.701 6.3139 9.285 6.2519 11.763 5.6329L12.236 5.5149C14.714 4.8959 17.299 4.8339 19.803 5.3349L20.195 5.4129C20.664 5.5069 21 5.9169 21 6.3939V17.2329C21 17.8639 20.423 18.3369 19.804 18.2129V18.2129Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 8.076C17.334 8.015 16.667 7.988 16 8.005"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 15.9287C6.666 15.9897 7.333 16.0157 8 15.9997"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>,
  'Cash'
);

export default CashIcon;
