import React from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';

import arrow from './down-arrow.svg';

import { CheckboxField } from '../CheckboxField/CheckboxField';

import styles from './MultiSelect.module.scss';

export function MultiSelect({ title, customClassName, onChange, options, selectedOptions }) {
  const dropdownRef = React.useRef(null);
  const buttonRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggle = React.useCallback(
    (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      } else if (buttonRef.current && buttonRef.current.contains(event.target)) {
        setIsOpen(!isOpen);
      }
    },
    [dropdownRef, buttonRef, isOpen]
  );

  const handleSelectOption = React.useCallback(
    (event) => {
      const { value, checked } = event.target;
      onChange(value, checked);
    },
    [onChange]
  );

  React.useEffect(() => {
    document.addEventListener('click', handleToggle, true);

    return () => {
      document.removeEventListener('click', handleToggle, true);
    };
  }, [handleToggle]);

  return (
    <div
      tabIndex="0"
      className={cn({
        [styles.dropdownWrapper]: true,
        [customClassName]: !!customClassName,
      })}
    >
      <div
        className={cn({
          [styles.dropdownButton]: true,
          [styles.active]: isOpen,
          [`${customClassName}--button`]: !!customClassName,
        })}
        ref={buttonRef}
      >
        {title}
        <img src={arrow} className={cn({ [styles.active]: isOpen })} alt="arrow" />
      </div>

      <div
        className={cn({
          [styles.dropdownContent]: true,
          [styles.showContent]: isOpen,
          [`${customClassName}--content`]: !!customClassName,
        })}
        ref={dropdownRef}
      >
        <div
          className={cn({
            [styles.innerDropdownButton]: true,
            [styles.innerButtonActive]: isOpen,
            [`${customClassName}--button`]: !!customClassName,
          })}
          onClick={() => handleToggle(false)}
        >
          {title}
          <img src={arrow} className={cn({ [styles.innerButtonActive]: isOpen })} alt="arrow" />
        </div>

        <hr />

        <div className={styles.optionsBlock}>
          {options.map((option) => (
            <div key={option.value} id={`${option.value}-checkbox`}>
              <label className={styles.label} htmlFor={`${option.value}-checkbox`}>
                <CheckboxField
                  title={option.label}
                  onChange={handleSelectOption}
                  value={option.value}
                  checked={selectedOptions && selectedOptions.indexOf(option.value) > -1}
                  filter
                />
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

MultiSelect.propTypes = {
  title: propTypes.string.isRequired,
  customClassName: propTypes.string,
  onChange: propTypes.func.isRequired,
  options: propTypes.arrayOf(propTypes.shape({ label: propTypes.string, value: propTypes.string }))
    .isRequired,
  selectedOptions: propTypes.arrayOf(propTypes.string),
};

MultiSelect.defaultTypes = {
  customClassName: '',
};
