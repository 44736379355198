import React from 'react';
import createIcon from '../utils/createIcon';

const CheckIcon = createIcon(
  <g>
    <path
      d="M13.3332 4.33325L5.99984 11.6666L2.6665 8.33325"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>,
  'Check'
);

export default CheckIcon;
