// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import cn from 'classnames';
import mergeRefs from '../utils/mergeRefs';
import styles from './TextField.module.scss';

const DATE_TYPES = ['date', 'time'];

export const TextField = React.forwardRef(function TextField(
  {
    type,
    description,
    error,
    name,
    label,
    icon,
    value,
    defaultValue,
    inputProps,
    onChange,
    onClick,
    onKeyDown,
    onBlur,
    onFocus,
    button,
    customClassName = '',
  },
  ref
) {
  const [state, setState] = useState({
    focused: false,
    touched: false,
  });

  const inputRef = useRef(null);
  const inputEl = useMemo(() => mergeRefs([inputRef, ref]), [ref]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      touched: (inputRef && inputRef.current && !!inputRef.current.value) || value || defaultValue,
    }));
  }, [inputRef, defaultValue, state.focused, value]);

  const handleFocus = useCallback(() => {
    if (onFocus) {
      onFocus();
    }
    setState((prev) => ({ ...prev, focused: true }));
  }, [onFocus]);

  const handleBlur = useCallback(() => {
    if (onBlur) {
      onBlur();
    }
    setState((prev) => ({ ...prev, focused: false }));
  }, [onBlur]);

  const handleChange = useCallback((e) => onChange(e.target.value), [onChange]);

  const handleClick = useCallback(() => onClick(), [onClick]);

  const handleOnKeyDown = useCallback((e) => onKeyDown(e), [onKeyDown]);

  const isTextArea = inputProps && inputProps.rows && inputProps.rows > 1;

  return (
    <div
      className={cn({
        [styles.root]: true,
        [customClassName]: !!customClassName,
      })}
    >
      <label
        className={cn({
          [styles.label]: true,
          [styles.focused]: state.focused,
          [styles.touched]: DATE_TYPES.includes(type) || state.touched,
          [styles.error]: error,
          [`${customClassName}-label-focused`]: !!customClassName && state.focused,
          [`${customClassName}-label-touched`]:
            (!!customClassName && DATE_TYPES.includes(type)) || state.touched,
          [`${customClassName}-label-error`]: !!customClassName && error,
        })}
        htmlFor={name}
      >
        {label}
      </label>
      <div
        className={cn({
          [styles.inputWrapper]: true,
          [`${customClassName}-input-wrapper`]: !!customClassName,
        })}
      >
        <div
          className={cn({
            [styles.formInput]: true,
            [styles.focused]: state.focused,
            [styles.error]: error,
            [`${customClassName}-form-input`]: !!customClassName,
            [`${customClassName}-form-input--focused`]: !!customClassName && state.focused,
            [`${customClassName}-form-input--error`]: !!customClassName && error,
          })}
        >
          {!isTextArea ? (
            <input
              /* eslint-disable react/jsx-props-no-spreading */
              {...inputProps}
              {...(onChange ? { onChange: handleChange } : {})}
              {...(onClick ? { onClick: handleClick } : {})}
              {...(value !== undefined ? { value } : {})}
              {...(defaultValue !== undefined ? { defaultValue } : {})}
              {...(onKeyDown ? { onKeyDown: handleOnKeyDown } : {})}
              /* eslint-enable react/jsx-props-no-spreading */
              ref={inputEl}
              onFocus={handleFocus}
              onBlur={handleBlur}
              className={styles.input}
              type={type}
              name={name}
            />
          ) : (
            <textarea
              /* eslint-disable react/jsx-props-no-spreading */
              {...inputProps}
              {...(onChange ? { onChange: handleChange } : {})}
              {...(onClick ? { onClick: handleClick } : {})}
              {...(value !== undefined ? { value } : {})}
              {...(defaultValue !== undefined ? { defaultValue } : {})}
              {...(onKeyDown ? { onKeyDown: handleOnKeyDown } : {})}
              /* eslint-enable react/jsx-props-no-spreading */
              ref={inputEl}
              onFocus={handleFocus}
              onBlur={handleBlur}
              className={cn({ [styles.input]: true, [styles.textarea]: true })}
              type={type}
              name={name}
            />
          )}

          {icon && <span className={styles.icon}>{icon}</span>}
        </div>
        {button && <div className={styles.buttonWrapper}>{button}</div>}
      </div>
      {error && <div className={styles.errorLabel}>{error}</div>}
      {description && <div className={styles.description}>{description}</div>}
    </div>
  );
});

TextField.defaultProps = {
  type: 'text',
  description: undefined,
  error: undefined,
  label: '',
  inputProps: {},
  defaultValue: undefined,
  value: undefined,
  onChange: undefined,
  onClick: undefined,
  onKeyDown: undefined,
  onBlur: undefined,
  onFocus: undefined,
  icon: undefined,
  button: undefined,
  customClassName: '',
};
