import React from 'react';
import cn from 'classnames';

import * as styles from './index.module.css';

export type NotificationMarkProps = { className?: string };

export function NotificationMark({ className }: NotificationMarkProps) {
  return <div className={cn(className, styles.notification)} />;
}
