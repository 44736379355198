import React from 'react';
import * as Ariakit from '@ariakit/react';
import cn from 'classnames';
import propTypes from 'prop-types';

import styles from './ComboboxItem.module.scss';

export function ComboboxItem({ value, selected, customClassName }) {
  return (
    <Ariakit.ComboboxItem
      className={cn({
        [styles.comboboxItem]: true,
        [styles.selected]: selected,
        [`${customClassName}--comboboxItem`]: !!customClassName,
      })}
      value={value}
      focusOnHover
    >
      {value.title}
    </Ariakit.ComboboxItem>
  );
}

ComboboxItem.propTypes = {
  value: propTypes.shape({
    value: propTypes.oneOfType([propTypes.string, propTypes.number]),
    title: propTypes.string,
  }),
  selected: propTypes.bool.isRequired,
  customClassName: propTypes.string,
};

ComboboxItem.defaultProps = {
  customClassName: '',
};
