import React from 'react';
import createIcon from '../utils/createIcon';

const CardIcon = createIcon(
  <g>
    <path d="M3 9H21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.57 13H7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M18 19H6C4.343 19 3 17.657 3 16V8C3 6.343 4.343 5 6 5H18C19.657 5 21 6.343 21 8V16C21 17.657 19.657 19 18 19Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>,
  'Card'
);

export default CardIcon;
