import React from 'react';
import createIcon from '../utils/createIcon';

const SwapIcon = createIcon(
  <g>
    <circle cx="12" cy="12" r="12" />
    <path
      d="M17.3265 12.0214C17.3199 13.3747 16.7999 14.7347 15.7665 15.7714C13.6799 17.8514 10.3065 17.8514 8.22388 15.7714C7.85321 15.398 7.55188 14.9847 7.31055 14.5447"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66943 11.9133C6.68943 10.5733 7.20943 9.24667 8.22277 8.22667C10.3028 6.14401 13.6828 6.14401 15.7654 8.22667C16.1321 8.59734 16.4321 9.00667 16.6721 9.44667"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6333 9.45H16.9866V7.08667"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.36 14.5466H7V16.9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </g>,
  'Swap'
);

export default SwapIcon;
